import { createStore } from 'vuex'

export default createStore({
  state: {
    language: localStorage.getItem("language") || "Ch",
    issearch: false,
    lszisshow: false,
    slides: [
      { text: "首页", hiddens: false, keys: "home", urls: "/" },
      { text: "关于科城", hiddens: false, keys: "abouts" },
      { text: "科城品牌", hiddens: false, keys: "brand" },
      { text: "工作领域", hiddens: false, keys: "works" },
      { text: "研究成果及洞察", hiddens: false, keys: "results" },
      { text: "科城动态", hiddens: false, keys: "dynamics", urls: "/dynamics/dynamiInfos" },
      { text: "搜索", hiddens: true, keys: "search", urls: "/search" },
    ],
    abouts: [
      { title: "科城简介", keys: "introduction", pages: "/abouts/introduction" },
      { title: "理事长寄语", keys: "chairman", pages: "/abouts/chairman" },
      { title: "年度报告", keys: "annualReport", pages: "/abouts/annualReport" },
      { title: "团队成员", keys: "team", pages: "/abouts/team" },
      { title: "加入科城", keys: "join", pages: "/abouts/join" },
      { title: "联系我们", keys: "contact", pages: "/abouts/contact" },
    ],
    results: [
      { title: "研究成果", keys: "resultsInfo", pages: "/results/resultsInfo" },
      { title: "观点洞察", keys: "InsightInfos", pages: "/results/InsightInfos" },
    ],
    brand: [],
    works: [],
    configs: {
      banquan: "",
      beian: "",
      ewm: "",
      logo: "",
      name: "",
    },
    addres: {
      address: "",
      emaile: "",
      lat: "",
      lng: "",
      tel: "",
      username: "",
      youbian: "",
    },
    fanyiw: {
      introduction: { en_name: "Introduction of Coshare", name: "科城简介" },
      chairman: { en_name: "Words from Chair of the Board", name: "理事长寄语" },
      annualReport: { en_name: "Annual Report", name: "年度报告" },
      team: { en_name: "Team Members", name: "团队成员" },
      join: { en_name: "Join Coshare", name: "加入科城" },
      contact: { en_name: "Contact Us", name: "联系我们" },
      teamDetails: { en_name: "Member Details", name: "成员详情" },
      joinDetails: { en_name: "Recruitment Details", name: "招聘详情" },

      joinse: { en_name: "Job opportunities", name: "招聘动态" },
      actives: { en_name: "Activities", name: "动态列表" },
      delivers: { en_name: "Deliverables", name: "成果列表" },

      resultsInfo: { en_name: "Findings", name: "研究成果" },
      InsightInfos: { en_name: "Insights", name: "观点洞察" },
      resultsDetails: { en_name: "Details of Achievements", name: "成果详情" },
      InsightDetails: { en_name: "Insight Details", name: "洞察详情" },
      dynamiDetails: { en_name: "Kecheng Dynamic Details", name: "科城动态详情" },
    },
    fontalls: {
      home: { en_name: "Home", name: "首页" },
      abouts: { en_name: "About us", name: "关于科城" },
      brand: { en_name: "Brand", name: "科城品牌" },
      works: { en_name: "Work Fields", name: "工作领域" },
      results: { en_name: "Research and Insights", name: "研究成果及洞察" },
      dynamics: { en_name: "Dynamic", name: "科城动态" },
      search: { en_name: "search for", name: "搜索" },
    }
  },
  getters: {
  },
  mutations: {
    setlszisshow(state, jsons) {
      state.lszisshow = jsons;
    },
    setworks(state, jsons) {
      state.works = jsons;
    },
    setbrand(state, jsons) {
      state.brand = jsons;
    },
    setfontall(state, arrs) {
      var jons = state.fontalls
      arrs.forEach(element => {
        jons[element.key] = element
      });
      state.fontalls = jons;
      state.fanyiw = {
        ...state.fanyiw,
        ...jons
      };
    },

    setLanguage(state, jsons) {
      state.language = jsons;
      localStorage.setItem("language", jsons)
    },
    setissearch(state, jsons) {
      state.issearch = jsons;
    },
    setsearchTexts(state, jsons) {
      state.searchTexts = jsons;
    },
    setconfigs(state, jsons) {
      state.configs = jsons;
    },
    setaddres(state, jsons) {
      state.addres = jsons;
    },
  },
  actions: {
  },
  modules: {
  }
})
