import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/index/index.vue'),
    meta: { keys: "home", types: 1 }
  },
  {
    path: '/abouts',
    name: 'abouts',
    component: () => import('../views/abouts/abouts.vue'),
    children: [{
      path: 'introduction',
      name: 'introduction',
      component: () => import('@/views/abouts/introduction.vue'),
      meta: { keys: "abouts", types: 2, title: "introduction" }
    }, {
      path: 'chairman',
      name: 'chairman',
      component: () => import('@/views/abouts/chairman.vue'),
      meta: { keys: "abouts", types: 2, title: "chairman" }
    }, {
      path: 'annualReport',
      name: 'annualReport',
      component: () => import('@/views/abouts/annualReport.vue'),
      meta: { keys: "abouts", types: 2, title: "annualReport" }
    }, {
      path: 'team',
      name: 'team',
      component: () => import('@/views/abouts/team.vue'),
      meta: { keys: "abouts", types: 2, title: "team" }
    }, {
      path: 'join',
      name: 'join',
      component: () => import('@/views/abouts/join.vue'),
      meta: { keys: "abouts", types: 2, title: "join" }
    }, {
      path: 'contact',
      name: 'contact',
      component: () => import('@/views/abouts/contact.vue'),
      meta: { keys: "abouts", types: 2, title: "contact" }
    }, {
      path: 'teamDetails',
      name: 'teamDetails',
      component: () => import('@/views/abouts/teamDetails.vue'),
      meta: { keys: "abouts", types: 3, title: "team", texts: "teamDetails" }
    }, {
      path: 'joinDetails',
      name: 'joinDetails',
      component: () => import('@/views/abouts/joinDetails.vue'),
      meta: { keys: "abouts", types: 3, title: "join", texts: "joinDetails" }
    },
    ]
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('../views/brand/brand.vue'),
    meta: { keys: "brand", types: 1 }
  },
  {
    path: '/works',
    name: 'works',
    component: () => import('../views/works/works.vue'),
    meta: { keys: "works", types: 1 }
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('../views/results/results.vue'),
    children: [{
      path: 'resultsInfo',
      name: '研究成果',
      component: () => import('@/views/results/resultsInfo.vue'),
      meta: { keys: "results", types: 2, title: "resultsInfo" }
    }, {
      path: 'resultsDetails',
      name: '研究成果详情',
      component: () => import('@/views/results/resultsDetails.vue'),
      meta: { keys: "results", types: 3, title: "resultsInfo", texts: "resultsDetails" }
    }, {
      path: 'InsightInfos',
      name: '观点洞察',
      component: () => import('@/views/results/InsightInfos.vue'),
      meta: { keys: "results", types: 2, title: "InsightInfos" }
    }, {
      path: 'InsightDetails',
      name: '观点洞察详情',
      component: () => import('@/views/results/InsightDetails.vue'),
      meta: { keys: "results", types: 3, title: "InsightInfos", texts: "InsightDetails" }
    }]
  },
  {
    path: '/dynamics',
    name: 'dynamics',
    component: () => import('../views/dynamics/dynamics.vue'),
    children: [{
      path: 'dynamiInfos',
      name: '科城动态',
      component: () => import('@/views/dynamics/dynamiInfos.vue'),
      meta: { keys: "dynamics", types: 1 }
    }, {
      path: 'dynamiDetails',
      name: '科城动态详情',
      component: () => import('@/views/dynamics/dynamiDetails.vue'),
      meta: { keys: "dynamics", types: 2, title: "dynamiDetails" }
    }]
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/search.vue'),
    meta: { keys: "search", types: 2, title: "results" }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
