<template>
  <div class="bottoms">
    <div class="flex_jcce">
      <div class="logose1 logos_wids scrolsimga"></div>
    </div>

    <div class="flex_jcce flex_wrap w12 botton_mains">
      <div class="flex botton_mains_blocks">
        <div class="botton_icons1 botton_wids scrolsimga1"></div>
        <div class="botton_mains_texts c_ffffff text-center">
          {{ language == "En" ? "Phone" : "电话" }}：{{ addres.tel }}
        </div>
      </div>
      <div class="flex botton_mains_blocks">
        <div class="botton_icons2 botton_wids scrolsimga1"></div>
        <div class="botton_mains_texts c_ffffff text-center">
          {{ language == "En" ? "Email" : "邮箱" }}：{{ addres.emaile }}
        </div>
      </div>
      <div class="flex botton_mains_blocks">
        <div class="botton_icons3 botton_wids scrolsimga1"></div>
        <div class="botton_mains_texts c_ffffff text-center">
          {{ language == "En" ? "Address" : "地址" }}：{{ addres.address }}
        </div>
      </div>
    </div>

    <div
      class="scrolsimga qrcodnse"
      :style="{
        backgroundImage: 'url(' + configs.ewm + ')',
      }"
    ></div>

    <div class="botton_botoms flex_jcce">
      <div class="botton_texts c_ffffff">
        {{ configs.banquan }}
      </div>
      <div
        @click="pageChange1(1)"
        class="botton_texts c_ffffff botton_texts_lefts cur_po"
      >
        {{ configs.beian }}
      </div>
      <div @click="pageChange1(2)" class="botton_texts c_ffffff cur_po">
        {{ configs.jszc }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance(); //关键代码
import { useStore } from "vuex";
const store = useStore();
const language = computed(() => store.state.language);
const configs = computed(() => store.state.configs);
const addres = computed(() => store.state.addres);

const pageChange1 = (types) => {
  const aTag = document.createElement("a");
  aTag.target = "_blank";
  if (types == 2) {
    aTag.href = "http://www.bjkhrs.com/";
  } else {
    aTag.href = "https://beian.miit.gov.cn/#/Integrated/index";
  }
  aTag.click();
};
</script>

<style>
@media screen and (max-width: 768px) {
  .bottoms {
    padding: 47rem 15rem 40rem;
    background: linear-gradient(180deg, #078f77 0%, #09695e 100%);
  }
  .botton_mains {
    padding: 40rem 0 10rem;
    flex-direction: column;
  }
  .botton_mains_blocks {
    margin-bottom: 15rem;
  }
  .botton_mains_texts {
    font-size: 24rem;
    line-height: 1.5;
    flex: 1;
  }
  .botton_wids {
    width: 28rem !important;
    height: 28rem !important;
    margin: 6rem 8rem 0 0;
  }
  .qrcodnse {
    width: 100rem !important;
    height: 100rem !important;
    margin: 0 auto 60rem;
  }
  .botton_botoms {
    flex-direction: column;
  }
  .botton_texts {
    font-size: 24rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 768px) {
  .bottoms {
    padding: 50px 0 20px;
    background: linear-gradient(180deg, #078f77 0%, #09695e 100%);
  }
  .logo_wids {
    height: 40px !important;
    width: 280px !important;
  }
  .botton_mains {
    padding: 45px 0 30px;
  }
  .botton_mains_blocks {
    margin-bottom: 8px;
  }
  .botton_mains_texts {
    font-size: 16px;
    flex: 1;
  }
  .botton_wids {
    width: 20px !important;
    height: 20px !important;
    margin: 2px 5px 0 50px;
  }
  .botton_texts {
    font-size: 16px;
  }
  .botton_texts_lefts {
    margin: 0 30px;
  }
  .qrcodnse {
    width: 90px !important;
    height: 90px !important;
    margin: 0 auto 45px;
  }
}
</style>
