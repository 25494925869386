import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus' //1.引入组件
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css' //2.引入CSS
import { request } from './utils/request.js'
import { timestampToTime } from './utils/config.js'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.request = request
app.config.globalProperties.timestampToTime = timestampToTime
app.use(ElementPlus).use(store).use(router).mount('#app')
