import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '../store'

var baseUrls = 'https://www.e-coshare.com/api/'

const service = axios.create({
  baseURL: baseUrls, // url = base url + request url
  timeout: 5000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // config.baseURL=sessionStorage.getItem('APP_URL')+"/api/"
    if (sessionStorage.getItem('jwttoken')) {
      config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('jwttoken')}`
    }
    config.headers['language'] = store.state.language == "En" ? 'en' : 'ch'
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    return config
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status == 200) {
      return Promise.resolve(res)
    } else {
      ElMessage({
        message: res.msg || 'Error',
        type: 'error'
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
    ElMessage({
      message: error.response.data.message || '请求发生了错误，请稍后再试',
      type: 'error'
    })
    return Promise.reject(error)
  }
)
export function request(url, option) {
  return new Promise((resolve, reject) => {
    let { methods, data, params, headers } = option
    service({
      url,
      method: methods || 'post',
      data,
      params,
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}