<template>
  <div class="headers">
    <div class="headers_tops">
      <div class="bg_ffffff">
        <div class="header_mains">
          <div class="bg_ffffff flexs header_blocks">
            <div
              @click="pageChange('/')"
              class="bg_ffffff header_blocks_logos flexs"
            >
              <div class="logoses logos_wids scrolsimga"></div>
            </div>
          </div>
          <div
            :class="showSlide ? 'header_slide_blocks' : ''"
            class="header_slide"
          >
            <div v-for="(item, index) in slides" :key="index">
              <div v-if="!item.hiddens" class="header_slide_items cur_po">
                <div
                  @click="checkTitle(item, index)"
                  class="flexs header_slide_items_blocks"
                >
                  <div
                    class="header_slide_items_texts text-ellipsis-1 selector"
                    :class="item.keys == routeNames ? 'c_085f56' : 'c_333333'"
                  >
                    {{
                      language == "En"
                        ? fontalls[item.keys].en_name
                        : fontalls[item.keys].name
                    }}
                  </div>
                  <div v-if="!item.urls" class="header_slide_items_addicons">
                    +
                  </div>
                </div>
                <div class="header_slide_items_clicks bg_085f56"></div>
                <div
                  :class="showSlideins == index ? 'levers_shows_blocks' : ''"
                  class="levers_blocks"
                  v-if="!item.urls"
                >
                  <div class="levers bg_ffffff">
                    <div
                      v-for="(its, ins) in store.state[item.keys]"
                      :key="ins"
                      @click="checkLevers(its, item.keys)"
                    >
                      <div
                        v-if="
                          (item.keys == 'abouts' &&
                            its.keys == 'chairman' &&
                            lszisshow) ||
                          (item.keys == 'abouts' && its.keys != 'chairman') ||
                          item.keys != 'abouts'
                        "
                        class="levers_items cur_po"
                      >
                        <div
                          v-if="item.keys == 'brand' || item.keys == 'works'"
                          class="levers_items_texts c_999999"
                        >
                          {{ its.title }}
                        </div>
                        <div v-else class="levers_items_texts c_999999">
                          {{
                            language == "En"
                              ? fanyiw[its.keys].en_name
                              : fanyiw[its.keys].name
                          }}
                        </div>
                        <div class="levers_rights_iconse">
                          <div
                            class="rights levers_rights_withs scrolsimga1"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="ispcs" class="flexs bg_ffffff header_rights">
            <div @click="lanChanges" class="header_right_engh c_333333 cur_po">
              {{ language == "En" ? "Ch" : "En" }}
            </div>
            <div class="header_right_margins bg_767676"></div>
            <div
              @click="tosearchs"
              class="seachser seach_wids scrolsimga cur_po"
            ></div>
          </div>
          <div v-if="!ispcs" class="flexs bg_ffffff header_rights">
            <div
              v-if="!showSlide"
              @click="tosearchs"
              class="seachser seach_wids scrolsimga cur_po"
            ></div>
            <div
              v-if="!showSlide"
              @click="showclicks"
              class="slides slides_wids scrolsimga cur_po"
            ></div>
            <div
              v-if="showSlide"
              @click="lanChanges"
              class="bg_e9e9e9 header_rights slides_wids_blockslsts"
            >
              <div
                :class="language != 'En' ? 'bg_085f56 c_ffffff' : ''"
                class="slides_wids_blocks flex_jcce"
              >
                中
              </div>
              <div
                :class="language == 'En' ? 'bg_085f56 c_ffffff' : ''"
                class="slides_wids_blocks flex_jcce"
              >
                En
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="headers_kong"></div>

    <div v-if="routeNames == 'home'" class="bannerblos">
      <el-carousel
        class="bannerblos_tops"
        :autoplay="autoplays"
        :interval="4000"
        indicator-position="none"
        @change="changese"
      >
        <el-carousel-item v-for="item in banner" :key="item">
          <div class="bannerblos_blocks">
            <div
              class="scrolsimga"
              :style="{ backgroundImage: 'url(' + item.img + ')' }"
            ></div>
            <div class="bannerblos_texts">
              <div class="bannerblos_texts1 c_ffffff" v-html="item.title"></div>
              <div
                class="bannerblos_texts2 c_ffffff"
                v-html="item.content"
              ></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banner_bottom">
        <div class="scrolsimga shubiao banner_icons"></div>
        <div class="banner_blocks flex_jcce">
          <div
            class="banner_blocks_items bg_ffffff"
            v-for="(item, index) in banner"
            :key="index"
          >
            <div :class="pages == index ? 'banner_blocks_checks' : ''"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner_images" v-else>
      <div
        class="scrolsimga"
        :style="{ backgroundImage: 'url(' + bannerimag + ')' }"
      ></div>
      <div class="bannerblos_textsslice">
        <div
          v-if="fanyiw[routeNames] && routeNames != 'search'"
          class="bannerblos_texts1 c_ffffff"
        >
          {{
            language == "En"
              ? fanyiw[routeNames].en_name
              : fanyiw[routeNames].name
          }}
        </div>
        <div
          v-if="fanyiw.results && routeNames == 'search'"
          class="bannerblos_texts1 c_ffffff"
        >
          {{ language == "En" ? fanyiw.results.en_name : fanyiw.results.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance(); //关键代码
import { useStore } from "vuex";
const store = useStore();

const autoplays = ref(true);
const slides = computed(() => store.state.slides);
const language = computed(() => store.state.language);
const fontalls = computed(() => store.state.fontalls);
const fanyiw = computed(() => store.state.fanyiw);
const issearch = computed(() => store.state.issearch);
const lszisshow = computed(() => store.state.lszisshow);
let routeNames = ref(router.currentRoute.value.meta.keys);
let showSlide = ref(false);
let showSlideins = ref(-1);

watch(
  () => router.currentRoute.value.meta,
  () => {
    routeNames.value = router.currentRoute.value.meta.keys;
    getchangefont();
  }
);
watch(
  () => store.state.language,
  () => {
    getchangefont();
  }
);
let banner = ref([]);
let bannerimag = ref("");
let pages = ref(0);

const getchangefont = () => {
  var keys = router.currentRoute.value.meta.keys;

  if (keys == "home") {
    proxy.request("index/indexAd", {}).then((res) => {
      res.data.forEach((items) => {
        items.title = items.title.replace(/\r\n/g, "<br>");
        items.content = items.content.replace(/\r\n/g, "<br>");
      });
      banner.value = res.data;
    });
  } else {
    var typejson = {
      abouts: "about",
      brand: "pinpai",
      works: "gzly",
      results: "yjcg",
      dynamics: "dongtai",
      search: "dongtai",
    };
    proxy
      .request("index/bjt", { data: { type: typejson[keys] } })
      .then((res) => {
        bannerimag.value = res.data;
      });
  }
};

const changese = (even) => {
  pages.value = even;
};
const showclicks = () => {
  showSlide.value = !showSlide.value;
  showSlideins.value = -1;
};

const checkTitle = (items, ins) => {
  if (items.urls) {
    showclicks();
    pageChange(items.urls);
  } else {
    showSlideins.value = ins;
  }
};
const checkLevers = (items, keys) => {
  showclicks();
  if (keys == "brand") {
    pageChange("/brand?ids=" + items.id);
  } else if (keys == "works") {
    pageChange("/works?ids=" + items.id);
  } else {
    pageChange(items.pages);
  }
};
const lanChanges = () => {
  store.commit("setLanguage", language.value == "En" ? "Ch" : "En");
};
const tosearchs = () => {
  store.commit("setissearch", !store.state.issearch);
  pageChange("/search");
};

const pageChange = (urls) => {
  window.scrollTo(0, 0);
  router.push(urls);
};
const ispcs = ref(true);
const setHtmlFontSize = () => {
  var windowWidth = document.documentElement.offsetWidth;
  if (windowWidth > 750) {
    ispcs.value = true;
  } else {
    ispcs.value = false;
  }
};
setHtmlFontSize();
window.addEventListener("resize", setHtmlFontSize);
</script>

<style>
@media screen and (max-width: 768px) {
  .slides_wids_blockslsts {
    display: flex;
    border-radius: 10rem;
  }
  .slides_wids_blocks {
    width: 50rem;
    height: 40rem;
    border-radius: 10rem;
    font-size: 28rem;
  }

  .headers_tops {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .headers_kong {
    height: 100rem;
  }
  .header_mains {
    margin: 0 30rem;
    height: 100rem;
    position: relative;
  }
  .header_blocks {
    position: absolute;
    left: 0;
    height: 100rem;
    z-index: 100;
  }
  .header_rights {
    position: absolute;
    right: 0;
    height: 100rem;
  }
  .header_rights {
    margin-right: 30rem;
  }
  .seach_wids {
    width: 30rem !important;
    height: 30rem !important;
    margin-right: 40rem;
  }
  .slides_wids {
    width: 38rem !important;
    height: 28rem !important;
  }
  .header_slide {
    position: fixed;
    top: 100rem;
    left: 100vw;
    right: 0;
    bottom: 0;
    z-index: 300;
    background: #fff;
    overflow: auto;
    display: none;
  }
  .header_slide_blocks {
    left: 0;
    display: block;
  }
  .header_slide_items {
    border-bottom: 1px solid #ebebeb;
    margin: 0 30rem;
  }
  .header_slide_items_blocks {
    height: 110rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header_slide_items_texts {
    font-size: 28rem;
  }
  .header_slide_items_addicons {
    font-size: 50rem;
  }
  .levers_blocks {
    display: none;
  }
  .levers_shows_blocks {
    display: block;
  }
  .levers_items {
    height: 100rem;
    display: flex;
    align-items: center;
  }
  .levers_items_texts {
    font-size: 28rem;
  }
  .bannerblos {
    position: relative;
  }
  .bannerblos_tops {
    height: 900rem !important;
    background: #018440;
  }
  .bannerblos_tops .el-carousel__container {
    height: 100%;
    position: relative;
  }
  .banner_images {
    height: 400rem !important;
    position: relative;
    background: #018440;
  }
  .bannerblos_blocks {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .bannerblos_texts {
    position: absolute;
    left: 30rem;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 !important;
  }
  .bannerblos_textsslice {
    position: absolute;
    left: 30rem;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 !important;
  }
  .bannerblos_texts1 {
    font-size: 50rem;
    font-weight: bold;
  }
  .bannerblos_texts2 {
    padding-top: 40rem;
    font-size: 28rem;
  }
  .banner_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40rem;
  }
  .banner_icons {
    width: 25rem !important;
    height: 34rem !important;
    margin: 0 auto 40rem;
  }
  .banner_blocks_items {
    width: 10rem !important;
    height: 10rem !important;
    border-radius: 50%;
    margin: 0 15rem;
    position: relative;
  }
  .banner_blocks_checks {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 0.1rem solid #ffffff;
    border-radius: 50%;
    transform: scale(3.2);
  }
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .header_mains {
    margin: 0 10px;
    padding-left: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_slide_items {
    font-size: 16px;
    margin: 0 15px;
  }
  .bannerblos_texts {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .bannerblos_textsslice {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10%;
  }
}
@media screen and (min-width: 1400px) {
  .header_mains {
    margin: 0 130px;
    padding-left: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_slide_items {
    font-size: 18px;
    margin: 0 28px;
  }
  .bannerblos_texts {
    position: absolute;
    left: 130px;
    right: 130px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .bannerblos_textsslice {
    position: absolute;
    left: 130px;
    right: 130px;
    bottom: 10%;
  }
}
@media screen and (min-width: 768px) {
  .slides_wids_blockslsts {
    display: none;
  }
  .header_blocks {
    position: absolute;
    left: 0;
    height: 100px;
    z-index: 500;
  }
  .header_rights {
    position: absolute;
    right: 0;
    height: 100px;
  }
  .bannerblos {
    position: relative;
  }
  .bannerblos_tops {
    height: 60vh !important;
  }
  .bannerblos_tops .el-carousel__container {
    height: 100%;
    position: relative;
  }
  .banner_images {
    height: 30vh !important;
    position: relative;
  }
  .bannerblos_blocks {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .bannerblos_texts1 {
    font-size: 50px;
    font-weight: bold;
  }
  .bannerblos_texts2 {
    padding-top: 40px;
    font-size: 26px;
  }
  .banner_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 35px;
  }
  .banner_icons {
    width: 25px !important;
    height: 35px !important;
    margin: 0 auto 35px;
  }
  .banner_blocks_items {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    margin: 0 16px;
    position: relative;
  }
  .banner_blocks_checks {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 1px solid #ffffff;
    border-radius: 50%;
    transform: scale(2.2);
  }

  .header_right_engh {
    font-size: 18px;
  }
  .header_right_margins {
    width: 2px;
    height: 18px;
    margin: 0 18px;
  }

  .headers_tops {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .headers_kong {
    height: 100px;
  }
  .header_slide {
    display: flex;
    align-items: center;
  }
  .header_slide_items {
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .header_slide_items_texts {
    font-size: 18px;
  }
  .header_slide_items_addicons {
    display: none;
  }
  .header_slide_items_clicks {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 75px;
    height: 4px;
    transform: translateX(-50%);
    display: none;
  }

  .levers_blocks {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .levers {
    width: 960px;
    padding: 20px 120px 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .levers_items {
    width: 240px;
    height: 70px;
    margin: 0 40px 30px;
    border-bottom: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .levers_items_texts {
    font-size: 18px;
    flex: 1;
  }
  .levers_rights_iconse {
    width: 20px;
  }
  .levers_rights_withs {
    width: 18px !important;
    height: 18px !important;
    display: none;
  }
  .levers_items:hover {
    border-color: #085f56;
  }
  .levers_items:hover .levers_items_texts {
    color: #085f56;
  }
  .levers_items:hover .levers_rights_withs {
    display: block;
  }

  .seach_wids {
    width: 18px !important;
    height: 18px !important;
  }
  .slides_wids {
    display: none;
  }

  .header_slide_items:hover .header_slide_items_texts {
    color: #085f56 !important;
  }
  .header_slide_items:hover .header_slide_items_clicks {
    display: block;
  }
  .header_slide_items:hover .levers_blocks {
    height: auto !important;
  }
}
</style>
