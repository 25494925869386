
// 时间戳转换
export function timestampToTime(timestamp) {
  var date = new Date(timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();

  var mm = month > 9 ? month : "0" + month
  var dd = day > 9 ? day : "0" + day
  var hh = hour > 9 ? hour : "0" + hour
  var tt = minute > 9 ? minute : "0" + minute
  var ss = second > 9 ? second : "0" + second

  return year + '/' + mm + '/' + dd + ' ' + hh + ':' + tt + ':' + ss;

}