<template>
  <headers></headers>
  <router-view />
  <bottom></bottom>
</template>

<script setup>
import headers from "@/components/headers.vue";
import bottom from "@/components/bottom.vue";
import { onMounted, ref, computed, watch, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance(); //关键代码
import { useStore } from "vuex";
const store = useStore();

watch(
  () => store.state.language,
  () => {
    getworkLists();
    getbrandLists();
    getlxwm();
    getconfigs();
    getfonts();
  }
);

const getconfigs = () => {
  proxy.request("index/config", {}).then((res) => {
    store.commit("setconfigs", res.data);
  });
};

const getlxwm = () => {
  proxy.request("index/lxwm", {}).then((res) => {
    store.commit("setaddres", res.data);
  });
};

const getworkLists = () => {
  proxy.request("gzly/jieshao", {}).then((res) => {
    if (res.code == 1) {
      store.commit("setworks", res.data);
    }
  });
};
const getbrandLists = () => {
  proxy.request("pinpai/jieshao", {}).then((res) => {
    if (res.code == 1) {
      store.commit("setbrand", res.data);
    }
  });
};
const getfonts = () => {
  proxy.request("index/changefontall", {}).then((res) => {
    if (res.code == 1) {
      store.commit("setfontall", res.data);
    }
  });
};
const getlszisshow = () => {
  proxy.request("index/lszisshow", {}).then((res) => {
    if (res.code == 1) {
      store.commit("setlszisshow", res.data == 1);
    }
  });
};

getlszisshow();
getfonts();
getworkLists();
getbrandLists();
getlxwm();
getconfigs();

const setHtmlFontSize = () => {
  var windowWidth = document.documentElement.offsetWidth;
  var htmlFontSize = windowWidth / 750;
  if (windowWidth > 750) {
    htmlFontSize = 12;
  } else {
    htmlFontSize = windowWidth / 750;
  }
  document.querySelector("html").style.fontSize = htmlFontSize + "px";
};
setHtmlFontSize();
window.addEventListener("resize", setHtmlFontSize);
</script>

<style>
.ggimgs {
  max-width: 100%;
}
body {
  margin: 0;
  padding: 0;
  background: #fff;
}

.scrolsimga {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.scrolsimga1 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.flex {
  display: flex;
}
.flexs {
  display: flex;
  align-items: center;
}
.flex_ali_end {
  display: flex;
  align-items: flex-end;
}
.flex_jcsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_sb {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flex_jcce {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_ais_jcce {
  display: flex;
  justify-content: center;
}
.flex_aic_jccfe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex_wrap {
  flex-wrap: wrap;
}
.cur_po {
  cursor: pointer;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text_justify {
  text-align: justify;
}
.text-ellipsis-2 {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.text-ellipsis-1 {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.text-ellipsis-3 {
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ov_hi {
  overflow: hidden;
}

.selector {
  white-space: nowrap;
}
.tran180 {
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .w12 {
    margin: 0 30rem;
  }
  .titles {
    font-size: 44rem;
    font-weight: bold;
    color: #085f56;
    margin-bottom: 35rem;
  }
  .details {
    font-size: 28rem;
  }
  .logos_wids {
    width: 275rem !important;
    height: 40rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .w12 {
    width: 96%;
    margin: 0 auto;
  }
  .titles {
    font-weight: bold;
    font-size: 44px;
    color: #085f56;
    margin-bottom: 55px;
  }
  .details {
    font-size: 16px;
  }
  .logos_wids {
    width: 275px !important;
    height: 40px !important;
  }
}
@media screen and (min-width: 1200px) {
  .w12 {
    width: 1200px;
    margin: 0 auto;
  }
  .titles {
    font-weight: bold;
    font-size: 44px;
    color: #085f56;
    margin-bottom: 55px;
  }
  .details {
    font-size: 18px;
  }
  .logos_wids {
    width: 275px !important;
    height: 40px !important;
  }
}

.c_085f56 {
  color: #085f56;
}
.c_ffffff {
  color: #ffffff;
}
.c_999999 {
  color: #999999;
}
.c_949494 {
  color: #949494;
}
.c_666666 {
  color: #666666;
}
.c_333333 {
  color: #333333;
}
.c_cccccc {
  color: #cccccc;
}
.bg_085f56 {
  background: #085f56;
}
.bg_ffffff {
  background: #ffffff;
}
.bg_cccccc {
  background: #cccccc;
}
.bg_EDEDED {
  background: #ededed;
}
.bg_F9F9F9 {
  background: #f9f9f9;
}
.bg_f5f5f5 {
  background: #f5f5f5;
}
.bg_E8F4F3 {
  background: #e8f4f3;
}
.bg_f6ab4b {
  background: #f6ab4b;
}
.bg_efefef {
  background: #efefef;
}
.bg_e9e9e9 {
  background: #e9e9e9;
}
.bg_fafafa {
  background: #fafafa;
}
.bg_767676 {
  background: #767676;
}
.bg_bfbfbf {
  background: #bfbfbf;
}
.bg_e0e0e0 {
  background: #e0e0e0;
}

input {
  border: none;
  outline: none;
  background: transparent;
}

.beidi1 {
  background-image: url("@/assets/beidi1.png");
  background-position: 0 88%;
}
.zhi1 {
  background-image: url("@/assets/zhi1.png");
}
.zhi2 {
  background-image: url("@/assets/zhi2.png");
}
.logoses {
  background-image: url("@/assets/logos1.png");
}
.seachser {
  background-image: url("@/assets/seaches.png");
}
.seachser1 {
  background-image: url("@/assets/seaches1.png");
}
.close {
  background-image: url("@/assets/close.png");
}
.rights {
  background-image: url("@/assets/rights.png");
}
.botticon {
  background-image: url("@/assets/botticon.png");
}
.logose1 {
  background-image: url("@/assets/logos2.png");
}
.botton_icons1 {
  background-image: url("@/assets/icons1.png");
}
.botton_icons2 {
  background-image: url("@/assets/icons2.png");
}
.botton_icons3 {
  background-image: url("@/assets/icons3.png");
}
.abouts3 {
  background-image: url("@/assets/abouts3.png");
}
.abicon1 {
  background-image: url("@/assets/abicon1.png");
}
.abicon2 {
  background-image: url("@/assets/abicon2.png");
}
.abicon3 {
  background-image: url("@/assets/abicon3.png");
}
.abicon4 {
  background-image: url("@/assets/abicon4.png");
}
.abicon5 {
  background-image: url("@/assets/abicon5.png");
}
.brands1 {
  background-image: url("@/assets/brands1.png");
}
.shubiao {
  background-image: url("@/assets/shubiao.png");
}
.abouts2 {
  background-image: url("@/assets/abouts2.png");
}
.slides {
  background-image: url("@/assets/slides.png");
}
.abouts1 {
  background-image: url("@/assets/abouts1.png");
}
</style>
